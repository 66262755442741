import { Provider } from 'react-redux';
import { store } from './Components/Store/store';
import MenuRoutes from './menuRoutes';


function App() {
  return (
    <Provider store={store}>
      <div className='App'>
        <MenuRoutes> </MenuRoutes>
      </div>
    </Provider>
  )
}
export default App;
