import API_URL from "./webAPI";
import axios from "axios";

export const requestToken = async (email) => {
  try {
    console.log(email);
    const response = await axios.post(
      API_URL.slice(0, -4) + 'token',
      new URLSearchParams({
        grant_type: 'password',
        UserName: email,
        token_type: 'bearer'
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded', // or application/json
        },
      }
    );
    return response.data.access_token;
  } catch (error) {
    console.error('Error fetching token:', error);
  }
};


export const putData = async (url, param) => {
  try {
    const response = await axios.put(
      API_URL + url,
      param,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("authTokenKey")}`,
          'Content-Type': 'application/json', // or application/json
        },
      },
    );

    return JSON.parse(response.data);
  } catch (error) {
    console.error('Error during PUT request:', error.response ? error.response.data : error.message);
    throw error;
  }
};


export const postAddUser = async (url, param) => {
  try {
    const response = await axios.post(API_URL + url, param);
    return response;
  } catch (error) {
    console.error('Error during POST Add User request:', error.response ? error.response.data : error.message);
    throw error;
  }
};


export const postData = async (url, param) => {
  try {
    const response = await axios.post(
      API_URL + url,
      param,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("authTokenKey")}`,
          'Content-Type': 'application/json', // or application/json
        },
      },
    );
    return response;
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("auth");
      localStorage.removeItem("authTokenKey");
    }

  }
  console.log("response", response);
};

