import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';


export default function Home() {
    const navigate = useNavigate();

    useEffect(() => {
        console.log("header",localStorage.getItem("authTokenKey"));
    },[])

    return (
        <span>
            <div>
                <div> Home </div>
            </div> 
        </span>
    )
}